import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { cardSchema, initialValues } from '../config/CardSchema'
import CardProfileFormWrapper from '../components/CardProfileFormWrapper'
import CardPayinFormWrapper from '../components/CardPayinFormWrapper'
import CardPayoutFormWrapper from '../components/CardPayoutFormWrapper'
import Button from 'utils/Button'
import acceptIconImg from 'assets/images/icons/acceptIcon.png'
import CsModal from 'utils/Modal'
import { Link, NavLink } from 'react-router-dom'
import { getAgentData } from 'store/reducers/agentReducerSlice'
import { useDispatch } from 'react-redux'
import { createCard, getAllAgents, getCardDetails, updateCard } from 'api/api'
import { getCardsAll } from 'store/reducers/cardsReducersSlice'
import { toast } from 'react-toastify'
import { card_status } from 'utils/dropdownOptions'

const CardForm = ({ id, type, profileDetails }) => {
  const dispatch = useDispatch()
  // dummy

  const [loadingAllAgents, setLoadingAllAgents] = useState(false)
  const [profileList, setProfileList] = useState([
    'profile one',
    'profile one',
    'profile one',
    'profile one',
  ])
  const [agentList, setAllAgentList] = useState([])
  const [isLoadingProfiles, setIsLoadingProfiles] = useState(true)
  const [modalOpen, setModal] = useState({ open: false })

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    values.status = values.status.toUpperCase()
    if (values.card_type === 'DP') {
      values.payout_transaction_limit = 0
      values.payout_daily_max_limit = 0
      values.payout_per_transaction_min_limit = 0
      values.payout_per_transaction_max_limit = 0
      values.payout_card_fees = 0
    }
    if (values.card_type === 'WD') {
      values.payin_transaction_limit = 0
      values.payin_daily_max_limit = 0
      values.payin_per_transaction_min_limit = 0
      values.payin_per_transaction_max_limit = 0
      values.payin_card_fees = 0
    }

    if (type === 'add') {
      createCard(values)
        .then((res) => {
          dispatch(getCardsAll())
          setSubmitting(false)
          resetForm()
          setModal({ open: true })
        })
        .catch((err) => {
          console.log(err)
          toast.error(err.message || ' Something went wrong')
        })
    }

    if (type === 'edit') {
      updateCard(values, id)
        .then((res) => {
          toast.success('Updated card')
          setModal({ open: true })
        })
        .catch((err) => {
          console.log(err)
          toast.error(err.message || 'Something went wrong')
        })
    }
  }
  // function for not letting minues value to be typed in the inputs
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }

  // handle close modal
  const handleCloseModal = () => {
    setModal({ open: false })
  }

  useEffect(() => {
    const getAllAgentsData = () => {
      setLoadingAllAgents(true)
      getAllAgents(1000, 0)
        .then((res) => {
          setLoadingAllAgents(false)
          setAllAgentList([...res.data])
          // setAllAgentList(res)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    getAllAgentsData()

    return () => {
      getAllAgentsData()
    }
  }, [])

  return (
    <>
      <Formik
        initialValues={profileDetails || initialValues}
        validationSchema={cardSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, handleChange, values, errors }) => (
          <Form autoComplete='off'>
            <CardProfileFormWrapper
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              values={values}
              errors={errors}
              agentList={agentList}
              loadingAllAgents={loadingAllAgents}
              profileList={profileList}
              isLoadingProfiles={isLoadingProfiles}
              type={type}
              handleSubmit={handleSubmit}
            />
            <CardPayinFormWrapper
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              values={values}
              errors={errors}
              type={type}
              cardVal={values.card_type}
              handleSubmit={handleSubmit}
            />
            <CardPayoutFormWrapper
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              values={values}
              errors={errors}
              type={type}
              cardVal={values.card_type}
              handleSubmit={handleSubmit}
            />
            {type === 'edit' ? (
              <>
                <div className='border rounded md:mb-4 mb-2'>
                  <div className='md:px-3 px-2 py-1 mb-3'>
                    <h5 className='m-4 font-semibold text-2xl'>Status</h5>
                    <div className='md:mt-2'>
                      {/* 1 */}
                      <div className='flex flex-wrap my-1 items-center'>
                        <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                          <label htmlFor='status'>Card Status</label>
                        </div>
                        <div className='col_ col_md_4 col_12 flex items-center'>
                          <div className='w-full flex items-center'>
                            <select
                              name='status'
                              id='status'
                              className='w_100 select_2 cursor_pointer user_select_none w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                              onChange={handleChange}
                              handleSubmit={handleSubmit}
                              value={values.status}
                            >
                              <option value=''></option>
                              {card_status.map((item, ind) => (
                                <option key={ind} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className='col_md_5 col_12'>
                          <ErrorMessage
                            name='status'
                            component='p'
                            className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {type === 'add' ? (
              <div className='flex items-center justify-center gap-5'>
                <NavLink to='/dashboard/all-cards'>
                  <Button type='button' classes='cs_btn'>
                    Cancel
                  </Button>
                </NavLink>
                <Button classes='cs_btn bg-purple-500 text-white' type='submit'>
                  Create
                </Button>
              </div>
            ) : null}
            {type === 'edit' ? (
              <div className='flex items-center justify-center gap-5 my-5'>
                <NavLink to='/dashboard/all-cards'>
                  <Button type='button' classes='cs_btn'>
                    Cancel
                  </Button>
                </NavLink>
                <Button classes='cs_btn bg-purple-500 text-white' type='submit'>
                  Save
                </Button>
              </div>
            ) : null}
          </Form>
        )}
      </Formik>
      <CsModal open={modalOpen.open} onClose={handleCloseModal}>
        <div className='bg-white text-center fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-[1055] w-max h-max max-h-[calc(100%-100px) ] overflow-x-hidden overflow-y-auto outline-none min-w-[550px] max-w-[730px]  rounded md:px-3 md:py-4 px-2 py-3'>
          <div className='flex justify-center items-center my-2 '>
            <span className=' flex items-center mr-2'>
              <img src={acceptIconImg} alt='' />
            </span>
            <p className='ml-1'>
              {type === 'add'
                ? '  The agent has been created successfully'
                : '  The agent has been Updated successfully'}
            </p>
          </div>
          <div className='my-2 flex flex-wrap justify-center items-center'>
            <Link to='/dashboard/all-cards' className='cs_btn mx_2' onClick={handleCloseModal}>
              Back to Cards
            </Link>
          </div>
        </div>
      </CsModal>
    </>
  )
}

export default CardForm
