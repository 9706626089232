import { ErrorMessage, Field } from 'formik'
import React from 'react'
import Button from 'utils/Button'
import { card_type } from 'utils/dropdownOptions'

const CardProfileFormWrapper = ({
  values,
  handleChange,
  type,
  isSubmitting,
  agentList,
  loadingAllAgents,
  profileList,
  isLoadingProfiles,
  handleSubmit,
}) => {
  // --------------------
  return (
    <div className='border rounded md:mb-4 mb-2 mt-5'>
      <div className='md:px-3 px-2 py-1 mb-3'>
        <h5 className='m-4 font-semibold text-2xl'>
          {type === 'add' ? 'New Card Details' : 'Profile'}
        </h5>
        <div className='md:mt-2'>
          {/* 1 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='name'>
                Agent<b className='text-red-600'>*</b>
              </label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <select
                  name='agent_id'
                  id='agent_name'
                  className='w_100 select_2 cursor_pointer user_select_none w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  onChange={handleChange}
                  value={values.agent_id || ''}
                >
                  <option value='' disabled>
                    Select
                  </option>
                  {loadingAllAgents ? (
                    <option value='' disabled>
                      Loading agent list
                    </option>
                  ) : agentList?.length > 0 ? (
                    agentList?.map((v, i) => (
                      <option key={i} value={v.id}>
                        {v.name}
                      </option>
                    ))
                  ) : (
                    <option value='' disabled>
                      No agents found
                    </option>
                  )}
                </select>
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='agent_id'
                component='p'
                className='text-red-600 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 2 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='card_nickname'>
                Card Nick Name<b className='text-red-600'>*</b>
              </label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='card_nickname'
                  id='card_nickname'
                  placeholder='Card Nickname'
                  className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='card_nickname'
                component='p'
                className='text-red-600 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 3 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='card_holder_name'>
                Bank card holder Name<b className='text-red-600'>*</b>
              </label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='bank_card_holder_name'
                  id='name'
                  placeholder='Bank card holder name'
                  className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='bank_card_holder_name'
                component='p'
                className='text-red-600 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 4 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='bank_card_number'>
                Bank Card Number<b className='text-red-600'>*</b>
              </label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='bank_card_number'
                  id='name'
                  placeholder='Bank Name'
                  className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='bank_card_number'
                component='p'
                className='text-red-600 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 5 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='bank_name'>
                Bank Name<b className='text-red-600'>*</b>
              </label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='bank_name'
                  id='name'
                  placeholder='Account Name'
                  className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='bank_name'
                component='p'
                className='text-red-600 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 6 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='card_type'>
                Card Type<b className='text-red-600'>*</b>
              </label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <select
                  name='card_type'
                  id='card_type'
                  className='w_100 select_2 cursor_pointer user_select_none w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  onChange={handleChange}
                  value={values.card_type}
                >
                  <option value=''>Select card type</option>
                  {card_type.map((item, ind) => (
                    <option key={ind} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='card_type'
                component='p'
                className='text-red-600 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 7 */}
          {/* <div className="flex flex-wrap my-1 items-center">
            <div className="col_md_3 col_12 md:ml-auto pr-3 md:text-end">
              <label htmlFor="profile">
                Profile<b className="text-red-600">*</b>
              </label>
            </div>
            <div className="col_ col_md_4 col_12 flex items-center">
              <div className="w-full flex items-center">
                <select
                  name="commission_profile_id"
                  id="profile"
                  className="w_100 select_2 cursor_pointer user_select_none w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500"
                  onChange={handleChange}
                  //   value={values.card_ty || ""}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {card_type.map((item, ind) => (
                    <option key={ind} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col_md_5 col_12">
              <ErrorMessage
                name="commission_profile_id"
                component="p"
                className="text-red-600 w-full text-start md:ml-auto md:pl-2"
              />
            </div>
          </div> */}
          {/* ----------------------- */}
        </div>
      </div>
      {/* {type === "edit" ? (
        <div className="flex items-center justify-end mr-3 gap-5 my-5">
          <Button classes="cs_btn">Cancel</Button>
          <Button classes="cs_btn bg-purple-500 text-white" type="submit" >Save</Button>
        </div>
      ) : null} */}
    </div>
  )
}

export default CardProfileFormWrapper
