import axios from 'axios'

// ************ Auth *************
// signIn
export const signIn = async (data) => {
  return await axios.post('/auth/login', data)
}

// ====== Authenticated ==========

// ************ Merchant ***************

// Get all merchant list
export const getAllMerchant = async (query = '', take = '', skip = '') => {
  const response = await axios.get(`/merchants${query || ''}?take=${take}&skip=${skip}`)
  return response.data
}

// Create merchant
export const postMerchant = async (data) => {
  return await axios.post('/merchants', data)
}

// Get a specefic merchant
export const getOneMerchant = async (id = '') => {
  let response = await axios.get(`/merchants/${id}/`)
  return response.data
}
// Edit a merchant
export const editMerchant = async (data, id = '') => {
  return await axios.put(`/merchants/${id}`, data)
}

// Generate a new token
export const postNewPaymentToken = async (id = '') => {
  return await axios.post(`/payment-api-tokens?merchantId=${id}`)
}

// ************ fee settings ***************

// create  fee settings
export const getFeeSetting = async (id = '') => {
  let response = await axios.get(`/merchants/${id}/fee-settings`)
  return response.data
}
// Add  fee settings
export const addFeeSetting = async (data, id = '') => {
  return await axios.post(`/merchants/${id}/fee-settings`, data)
}
// Edit  fee settings
export const updateFeeSetting = async (data, id = '') => {
  return await axios.put(`/fee-settings/${id}`, data)
}
// Delete fee setting
export const deleteFeeSetting = async (id = '') => {
  return await axios.delete(`/fee-settings/${id}`)
}
// get assignment fee seeting
export const getFeeSettingAssignment = async (id = '') => {
  let res = await axios.get(`/merchants/${id}/fee-settings/get-assignments`)
  return res.data
}
// get cards priority fee settings
export const getCardPriorities = async (id = '') => {
  let res = await axios.get(`/fee-settings/${id}/card-priority`)
  return res.data
}

// Add  payment method
export const addPaymentMethod = async (data, id = '') => {
  return await axios.post(`/merchants/${id}/payment-methods`, data)
}
// Get  payment methods
export const getPaymentMethods = async (id = '') => {
  let response = await axios.get(`/merchants/${id}/payment-methods`)
  return response.data
}
// Update Payment Method
export const updatePaymentMethod = async (data, id = '') => {
  return axios.put(`/payment-methods/${id}`, data)
}
// Delete Payment Method
export const deletePaymentMethod = async (id = '') => {
  return await axios.delete(`/payment-methods/${id}`)
}

// ************ MERCHANT  ***************

// get all merchant users
export const getAllMerchantUsers = async (id = '', query = '', take = '', skip = '') => {
  let response = await axios.get(`/merchants/${id}/users?take=${take}&skip=${skip}`)
  return response.data
}
// create a merchant users
export const createMerchantUser = async (data, id = '') => {
  return await axios.post(`/merchants/${id}/users`, data)
}
// edit merchant user
export const editMerchantUser = async (data, id = '') => {
  return await axios.put(`/users/${id}`, data)
}

// Agent End Points---------------------------------------------------------------

// get all agents

export const getAllAgents = async (take = '', skip = '') => {
  let response = await axios.get(`/agents?take=${take}&skip=${skip}`)
  return response.data
}
// fetching data for dropdown parent account

export const getAgentDropdown = async (query = '') => {
  let response = await axios.get(`/agents`)
  return response.data
}

// create agent
export const createAgent = async (data) => {
  return await axios.post('/agents', data)
}
// Get one agent
export const getAgentdetails = async (id = '') => {
  let response = await axios.get(`/agents/${id}`)
  return response.data
}
// Edit agent
export const editAgent = async (data, id = '') => {
  return await axios.put(`/agents/${id}`, data)
}

// Agent Api's Done-----------------------------------------------------------------

// Commision Profile Api's

export const getAllProfiles = async (query = '', take = '', skip = '') => {
  let response = await axios.get(`/commission-profiles/${query}?take=${take}&skip=${skip}`)
  return response.data
}

// fetching data for dropdown commision profile
export const getCommissionProfilesDropdown = async (query = '') => {
  let response = await axios.get(`/commission-profiles`)
  return response.data
}

// create profile

export const createProfile = async (data) => {
  await axios.post(`/commission-profiles`, data)
}

// profile details
export const getProfileDetails = async (id = '') => {
  let res = await axios.get(`/commission-profiles/${id}`)
  return res.data
}

// edit profile
export const editProfile = async (data, id = '') => {
  await axios.put(`/commission-profiles/${id}`, data)
}

// --------------------------------------------------------------------
// Card API's Start

// create a card
export const createCard = async (data) => {
  await axios.post(`/cards`, data)
}

// get all cards
export const getAllCards = async (query = '', take = '', skip = '') => {
  let res = await axios.get(`/cards/${query}?take=${take}&skip=${skip}`)
  return res.data
}

// get card details
export const getCardDetails = async (id = '') => {
  return await axios.get(`/cards/${id}`)
}

//update a card
export const updateCard = async (data, id = '') => {
  return await axios.put(`/cards/${id}`, data)
}
// api for updating card priority
export const updateCardPriority = async (data, id = '') => {
  return await axios.put(`cards/${id}/card-priority`, data)
}
// do assignment
export const doAssignment = async (data, id = '') => {
  return await axios.post(`/cards/${id}/do-assignment`, data)
}

// do force assignment
export const doForceAssignment = async (data, id = '') => {
  return await axios.post(`/cards/${id}/force-assignment`, data)
}
// --------------------------------------------------------------------
// ************ USERS  ***************
// Users API's Start

export const getAllUsers = async (query = '', take = '', skip = '') => {
  let response = await axios.get(`/users${query}?take=${take}&skip=${skip}`)
  return response.data
}

// create user
export const createUser = async (data) => {
  return await axios.post('/users', data)
}
// Get one user
export const getUserdetails = async (id = '') => {
  let response = await axios.get(`/users/${id}`)
  return response.data
}
// Edit user
export const editUser = async (data, id = '') => {
  return await axios.put(`/users/${id}`, data)
}

// Change user password
export const changeUserPassword = async (data, id = '') => {
  return await axios.put(`/users/${id}/reset-password`, data)
}

// -------------------------------------------------------------------------------
// Checkout Apis atart

// for checkout confirm
// export const doConfirmCheckout = async (data, headers, id = "") => {
//   return await axios.post(`checkouts/do-confirm/${id}`, headers, data);
// };

// for checkout cancel
export const doCancelCheckout = async (headers, id = '') => {
  return await axios.post(`checkouts/do-cancel/${id}`, headers)
}

// ----------------------- GET PAYMENT data starts HERE ---------------------------------

// to get payments details
export const getAllPayments = async (take = '', skip = '') => {
  let response = await axios.get(`/payments?take=${take}&skip=${skip}`)
  return response.data
}

// to get single payment details
export const getPaymentDetails = async (id = '') => {
  let response = await axios.get(`/payments/${id}`)
  return response.data
}

export const createPaymentTimeline = async (id, data) => {
  return await axios.post(`/payments/${id}/timelines`, data)
}

export const doConfirmPayment = async (id, data) => {
  return await axios.post(`/payments/${id}/do-confirm`, data)
}
// ----------------------- GET ORDER data starts HERE ---------------------------------

export const getAllOrders = async (take = '', skip = '') => {
  let response = await axios.get(`/orders?take=${take}&skip=${skip}`)
  return response.data
}
export const getAllMerchantOrders = async (merchantId, take = '', skip = '') => {
  let response = await axios.get(`/orders/merchants/${merchantId}?take=${take}&skip=${skip}`)
  return response.data
}

export const createOrderTimeline = async (id, data) => {
  return await axios.post(`/orders/${id}/timelines`, data)
}

// to get single payment details
export const getOrderDetails = async (id = '') => {
  const response = await axios.get(`/orders/${id}`)
  return response.data
}

export const getMerchantOrders = async (merchantsId, take, skip) => {
  const res = await axios.get(`/orders/merchants/${merchantsId}?take=${take}&skip=${skip}`)
  return res.data
}

// Wallets Temp

export const getAllWallets = async (query = '', take = '', skip = '') => {
  let response = await axios.get(`/wallets${query}?take=${take}&skip=${skip}`)
  return response.data
}

export const getWalletDetails = async (id = '') => {
  let response = await axios.get(`/v1/wallets/${id}`)
  return response.data
}
