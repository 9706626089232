import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ImBin2 } from 'react-icons/im'
import CryptoJS from 'crypto-js'
import { Buffer } from 'buffer'

import axios from 'axios'
import { getCheckoutDetails } from 'api/RESTful/checkout'

import { useLocalStorage } from 'hook/useLocalStorage'

import spinner from 'assets/images/svgs/small-spinner.svg'
import copy from 'assets/images/svgs/copy.svg'
import upload from 'assets/images/svgs/upload 1.svg'
import folder from 'assets/images/svgs/folder.svg'

import CheckoutExpired from './CheckoutExpired'
import CancelCheckout from './Cancel'
import CheckoutSuccessful from './CheckoutSuccessful'
import AddToBlockList from './AddToBlockList'

// --------------- Checkout page ----------------
const Checkout = () => {
  const generateFileChecksum = async (file) => {
    const reader = new FileReader()
    reader.onload = function (event) {
      const fileContentAsString = event.target.result // Now as a string
      // Assuming CryptoJS can handle strings directly
      const checksum = CryptoJS.MD5(fileContentAsString).toString()
      setCheckSum(checksum)
    }
    reader.readAsText(file, 'UTF-8') // Read the fil
  }

  const baseUrl = process.env.REACT_APP_BASE_URL
  const navigate = useNavigate()

  // loading state
  const [loading, setLoading] = useState({
    confirm: false,
    cancel: false,
  })
  // State variables to control component rendering
  const [showExpireComp, setshowExpireComp] = useState(false)
  const [showSuccessComp, setshowSuccessComp] = useState(false)
  const [showBlockComp, setshowBlockComp] = useState(false)
  const [showCancelComp, setshowCancelComp] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [showPspNameComp, setshowPspNameComp] = useState(true)
  const [selectedFile, setSelectedFile] = useState(null)
  const [checkSum, setCheckSum] = useState()
  // State variables for file upload and countdown
  const fileInputRef = useRef()
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')
  const id = urlParams.get('id')
  const created_at = urlParams.get('createdAt')

  // Countdown effect
  let gotTime = new Date(created_at).getTime()
  const targetTime = gotTime + 15 * 60 * 1000

  const calculateTimeLeft = () => {
    const currentTime = new Date().getTime()
    const difference = targetTime - currentTime
    return difference
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    if (timeLeft <= 0) {
      setshowExpireComp(true)
      setshowPspNameComp(false)
      navigateToMerchant()
      setTimeLeft(0) // Set timeLeft to 0 to avoid re-renders due to state changes
    } else {
      const interval = setInterval(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [timeLeft])

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0]
    if (file && ['image/jpg', 'image/jpeg', 'image/png'].includes(file.type)) {
      if (file?.size <= 3000000) {
        setSelectedFile(file) // Store the selected file in state
        await generateFileChecksum(file)
      } else {
        toast.error('File size exceeds 3MB. Please select a smaller file.')
      }
    } else {
      toast.error('Please select a valid jpg/jpeg/png file')
      console.log('This is not jpg/jpeg/png')
    }
  }

  // function for deleting uploaded file

  const handleDelete = () => {
    setSelectedFile(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)

    const droppedFiles = [...e.dataTransfer.files]
    setSelectedFile(droppedFiles)
  }
  const openFileInput = () => {
    fileInputRef.current.click()
  }
  const handleConfirmCheckout = () => {
    const formData = new FormData()
    if (selectedFile) {
      formData.append('file', selectedFile)
    }
    setLoading({ ...loading, confirm: true })

    axios
      .post(`${baseUrl}/checkouts/do-confirm/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token,
          Signature: checkSum,
        },
      })
      .then((response) => {
        setLoading({ ...loading, confirm: false })
        setshowSuccessComp(true)
        setshowPspNameComp(false)
        navigateToMerchant()
      })
      .catch((err) => {
        setLoading({ ...loading, confirm: false })
        setshowBlockComp(true)
        setshowPspNameComp(false)
        console.log(err)
      })
  }

  // function for navigating user the marchant component after 3 seconds of succes component
  const navigateToMerchant = () => {
    setTimeout(() => {
      setshowSuccessComp(false)
      window.close()
    }, 3000)
  }

  const handleCancelCheckout = () => {
    setLoading({ ...loading, cancel: true })
    axios
      .post(`${baseUrl}/checkouts/do-cancel/${id}`, null, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token,
          Signature: checkSum,
        },
      })
      .then((response) => {
        setLoading({ ...loading, cancel: false })
        setshowCancelComp(true)
        setshowPspNameComp(false)
        navigateToMerchant()

        // navigate("/checkout/cancelCheckout");
      })
      .catch((err) => {
        setLoading({ ...loading, cancel: false })
        setshowBlockComp(true)
        setshowPspNameComp(false)
        // navigate("/checkout/addToBlockList");
        console.log(err)
      })
  }
  const handleCopyToClipBoard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Text Copied to clipboard')
    })
  }

  const [checkoutDetails, setCheckoutDetails] = useState([])

  const fetchCheckoutDetails = async () => {
    try {
      const checkoutData = await getCheckoutDetails(id, token)
      setCheckoutDetails(checkoutData)
    } catch (error) {
      toast.error('Error fetching checkout details:', error)
    }
  }
  useEffect(() => {
    fetchCheckoutDetails()
  }, [])

  return (
    <>
      {showExpireComp && <CheckoutExpired />}
      {showCancelComp && <CancelCheckout />}
      {showSuccessComp && <CheckoutSuccessful />}
      {showBlockComp && <AddToBlockList />}
      {/*  */}
      {showPspNameComp && (
        <div className='flex items-center justify-center '>
          <div className=' min-h-[100vh] min-w-[50%] cursor-pointer'>
            <div className='flex items-center flex-col'>
              {/* ---------- */}
              <div className='flex items-center justify-center mt-20'>
                <h1 className='text-5xl font-bold'>PICOTOP</h1>
              </div>
              {/* ------------ */}
              <div className='w-[75%] my-3 flex flex-col gap-3'>
                <div className='bg-[#499CD626] w-[100%] flex items-center justify-center rounded-sm p-1'>
                  <span className='text-2xl'>交易途中请勿关掉此页面</span>
                </div>
                <div className='bg-[#499CD626] w-[100%] flex items-center justify-center rounded-sm p-1'>
                  <span className='text-2xl'>
                    转账有效时间为15分钟，剩余 {''}
                    <span className='text-red-600'>{Math.floor(timeLeft / 1000 / 60)}</span>
                    {''} 分 {''}
                    <span className='text-red-600'>{Math.floor((timeLeft / 1000) % 60)}</span> 秒
                  </span>
                </div>
                {/* ---------------------- */}
                <div className='my-3 flex flex-col gap-2'>
                  <div className='flex justify-between'>
                    <span className='text-xl font-normal'>银行名称：</span>
                    <span className='text-right flex gap-1 text-xl font-normal'>
                      {checkoutDetails.bank_name}
                      <img
                        src={copy}
                        alt=''
                        onClick={() => handleCopyToClipBoard(checkoutDetails.bank_name)}
                      />
                    </span>
                  </div>
                  <div className='flex justify-between'>
                    <span className='text-xl font-normal'>收款姓名：</span>
                    <span className='text-right flex gap-1 text-xl font-normal'>
                      {checkoutDetails.card_holder}
                      <img
                        src={copy}
                        alt=''
                        onClick={() => handleCopyToClipBoard(checkoutDetails.card_holder)}
                      />
                    </span>
                  </div>
                  <div className='flex justify-between'>
                    <span className='text-xl font-normal'>银行账号：</span>
                    <span className='text-right flex gap-1 text-xl font-normal'>
                      {checkoutDetails.bank_card_number}
                      <img
                        src={copy}
                        alt=''
                        onClick={() => handleCopyToClipBoard(checkoutDetails.bank_card_number)}
                      />
                    </span>
                  </div>
                  <div className='flex justify-between'>
                    <span className='text-xl font-normal'>转账金额：</span>
                    <span className='text-right flex gap-1 text-xl font-normal items-center'>
                      {' '}
                      <span className='text-ml'>{checkoutDetails.currency}</span>
                      {checkoutDetails.deposit_amount}
                      <img
                        src={copy}
                        alt=''
                        onClick={() => handleCopyToClipBoard(checkoutDetails.deposit_amount)}
                      />
                    </span>
                  </div>
                  <div className='flex justify-between'>
                    <span className='text-xl font-normal'>转账备注： </span>
                    <span className='text-right flex gap-1 text-xl font-normal'>
                      {checkoutDetails.v}
                      <img
                        src={copy}
                        alt=''
                        onClick={() => handleCopyToClipBoard(checkoutDetails.v)}
                      />
                    </span>
                  </div>
                </div>
                {/* ---------------------------- */}
                <div>
                  <div className='bg-[#01416D] w-[100%] flex items-center justify-start text-white rounded-sm p-1 text-xl pl-2'>
                    转账須知
                  </div>
                  <div className='flex flex-col gap-1 text-lg'>
                    <span>1. 登录您的 网上银行 账号。</span>
                    <span>2. 确认转账金额 并负责转账备注信息 到支付页面的备注处。</span>
                    <span>3. 完成转账。</span>
                    <span>4. 登录到网站并查询余额。</span>
                    <span>
                      5. 转账必须在30分钟完成 否则购买将视为无效。
                      请注意：会员在转账时必须填写正确转账备注信息。
                    </span>
                  </div>
                </div>
                {/* -------------------------- */}
                <div>
                  <div className='relative bg-[#01416D] w-[100%] flex items-center justify-start text-white rounded-sm p-1 text-xl pl-2 gap-4 cursor-pointer'>
                    上传凭证 <img src={upload} alt='' />
                  </div>
                  <div
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    className={`relative h-[100px] w-full  flex justify-center items-center border-dotted border-2 border-black  ${
                      dragging ? 'border-[#01416d]' : ''
                    } my-3 cursor-pointer`}
                  >
                    <div
                      onClick={handleDelete}
                      className={` ${
                        selectedFile ? 'grid' : 'hidden'
                      } absolute right-0 top-[10px] rounded-full grid w-[30px] h-[30px]`}
                    >
                      <ImBin2 style={{ fontSize: '20px', color: '#01416d' }} />
                    </div>
                    {selectedFile ? (
                      <button className='flex flex-col items-center justify-center'>
                        <img src={folder} alt='' />{' '}
                        <span className='truncate w-[40%]'>{selectedFile?.name}</span>
                      </button>
                    ) : (
                      <button className='cursor-pointer w-full h-full' onClick={openFileInput}>
                        {' '}
                        <span>拖放以上传或浏览文件</span>
                      </button>
                    )}

                    <input
                      type='file'
                      id='files'
                      ref={fileInputRef}
                      required
                      hidden
                      onChange={handleFileInputChange}
                    />
                  </div>
                </div>
                {/* ------------------------ */}
                <div className='my-5 flex items-center justify-center flex-wrap gap-3 '>
                  <button
                    className='bg-[#01416D] text-white rounded-sm px-5 py-1 min-w-[120px] flex justify-center items-center min-h-[35px]'
                    onClick={handleCancelCheckout}
                  >
                    {loading?.cancel ? <img src={spinner} /> : '取消订单'}
                  </button>
                  <button
                    className='bg-[#01416D] text-white rounded-sm px-5 py-1 cursor-pointer disabled:bg-[#405868]  min-h-[35px] disabled:text-[#7d8f9c] min-w-[200px] flex justify-center items-center'
                    disabled={selectedFile === null}
                    onClick={handleConfirmCheckout}
                  >
                    {loading?.confirm ? <img src={spinner} /> : '我已付款'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Checkout
